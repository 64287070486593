.loader {
  font-size: 25px;
  color: #000000;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  /* font-weight: 400; */
  position: relative;
}

.loader:after {
  content: '';
  height: 4px;
  width: 0%;
  display: block;
  background: #ff3d00;
  animation: 2s lineGrow linear infinite;
}

@keyframes lineGrow {
  to {
    width: 100%;
  }
}

.custom_loader_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
}

.custom_pincode_loader_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.custom_loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
