.DateRangePicker {
  border-radius: 10px !important;
}
.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.DayPicker {
  margin: 0px;
  padding: 0px;
}
.DayPicker__week {
  background-color: #eb1b1b;
}

.DayPicker__day {
  border: 1px solid #15a4c1;
}

.CalendarMonth .CalendarMonth_1 {
  color: #3498db;
  padding: 0px;
  margin: 0px;
  padding: 0px;
}

.DayPickerNavigation_button {
}
.CalendarMonth .CalendarDay__selected {
  background: #7cbadd;
  border: 1px solid #e4e7e7;

  color: #fff;
}

.CalendarDay__selected_span {
  background: #7cb9ddc5;
  border: 1px solid #e4e7e7;
}

.CalendarDay__selected_span:hover {
  background: #7cbadd;
  border: 1px solid #e4e7e7;
}
.CalendarDay__hovered_span {
  background: #7cb9ddc5;
  border: 1px solid #e4e7e7;
  color: #fff;
}
.CalendarDay__hovered_span:hover {
  background: #7cbadd;
  border: 1px solid #e4e7e7;
  color: #fff;
}
.CalendarMonth_table td {
  border: 1px solid #e4e7e7;
}
