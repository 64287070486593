.note {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 300px;
  margin: 0px 0px 10px 150px;
}

.note_title {
  font-size: 18px;
  font-weight: bold;
}

.note_content {
  font-size: 14px;
  margin-top: 10px;
}
.seller_div {
  margin: 0px 0px 5px 150px;
}
.set_modal {
  max-height: 850px;
}
.set_Products_Style {
  width: 100%;
  display: flex;
  height: auto !important;

  justify-content: space-between;
  padding-left: 10px;
}
.order_card {
  /* border: 1px solid #dfd6d6; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 28rem;
}
.order_card_wrapper {
  height: auto !important;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: auto;
  padding-bottom: 10px;
}
.calls_detail_wrapper {
  height: auto !important;
  max-height: 515px;
  overflow-y: scroll;
  overflow-x: auto;
  padding-bottom: 10px;
}
.total_price_set {
  transition: all 0.5s;
}
.total_price_set:hover {
  transform: scale(1.2);
}
