.printable_content {
  page-break-before: always;
  width: 100%;
  position: relative;
  padding: 0px 5px;
}

@media print {
  body * {
    visibility: hidden;
  }

  .printable_content,
  .printable_content * {
    visibility: visible;
    color: black;
  }

  .printable_content {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .row {
    page-break-inside: unset;
  }
}

@media screen {
  .print_visible {
    display: none;
  }
  /*body * {*/
  /*  visibility: hidden;*/
  /*}*/

  /*.printable_content,*/
  /*.printable_content * {*/
  /*  visibility: visible;*/
  /*  color: black;*/
  /*}*/

  /*.printable_content {*/
  /*  position: absolute;*/
  /*  !*top: 0%;*!*/
  /*  !*left: 0%;*!*/
  /*}*/

  /*.row {*/
  /*  page-break-inside: unset;*/
  /*}*/
}

/* .printable_content {
  page-break-before: always;
  padding: 10px 5px;
  width: 100%;
  height: 1087px;
  position: relative;
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .printable_content {
    page-break-before: always;
  }
  body * {
    visibility: hidden;
  }
  .printable_content * {
    visibility: visible;
    page-break-after: auto;
  }
}
@media screen {
  .print_visible {
    display: none;
  }
} */
