.address_header {
  position: relative;
}
.address_header::after {
  content: '';
  border: 1px solid #009ef7;
  position: absolute;
  top: 20px;
  left: 15px;
  width: 0;

  opacity: 0;
  transition: all 0.5s;
}
.salesman_header {
  position: relative;
}

.address_header:hover::after {
  width: 2rem;
  opacity: 1;
}

.salesman_header::after {
  content: '';
  border: 1px solid #009ef7;
  position: absolute;
  top: 55px;
  left: 30px;
  width: 0;
  opacity: 0;
  transition: all 0.5s;
}
.salesman_header:hover::after {
  width: 6rem;
  opacity: 1;
}

.salesman_header:hover h4 {
  color: #009ef7;
}
