.set_product_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.5s;
}
.set_product_image:hover {
  transform: scale(1.2);
}

.product_detail_img {
  height: 24rem;
}
.product_detail_img img {
  height: 100%;
}
.order_detail_h4 {
  position: relative;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}
.order_detail_h4 h4 {
  color: black;
}
.order_detail_h4::after {
  content: '';
  border: 1px solid #009ef7;
  position: absolute;
  top: 38px;
  left: 32px;
  width: 0;
  opacity: 0;
  transition: all 0.5s;
}

.order_detail_h4:hover::after {
  width: 6rem;
  opacity: 1;
}

.order_detail_h4:hover h4 {
  color: #009ef7;
}
.type_payment_mode {
  position: absolute;
  bottom: -7px;
  left: 20px;
}
.return_heading {
  position: relative;
}
.return_heading::after {
  content: '';
  border: 1px solid #55585a;
  position: absolute;
  top: 23px;
  left: 5px;
  width: 7rem;
  transition: all 0.5s;
}
.detail_wrapper {
  max-height: 350px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.pdf_wrapper {
  padding:5px;
  width: auto;
  height: 1087px;
}
.tab,
th,
td,
.invoice_div {
  border: 1px solid black;
  border-collapse: collapse;
}


/* ------ LOG BUTTON -------- */
.hover_container {
  position: relative;
  width: 300px;
  height: 100px;
  background-color: #f0f0f0;
  padding: 10px;
}

.item {
  cursor: pointer;
  position: relative;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #ccc;
  transition: background-color 0.3s ease;
}

.item:hover {
  background-color: #e0e0e0;
}

.details {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
}

.item:hover .details {
  display: block;
}
@media print {
  body * {
    visibility: hidden;
    overflow: hidden;
  }

  .printable_content * {
    visibility: visible;
  }

  .printable_content {
    position: absolute;
    left: 0;
    top: 0;
  }

  /* .pdf_wrapper {
    padding: 5px;
    width: 755px;
    height: 1087px;
  } */
}

@media screen {
  .print_visible {
    display: none;
    text-align: start; 
  }
}
